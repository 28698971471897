<template>
    <transition
      :name="routerTransition"
      mode="out-in"
    >
        <router-view></router-view>
    </transition>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
export default {
    setup() {
        const { routerTransition } = useAppConfig()

        return {
            routerTransition
        }
    },
}
</script>